 
import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { JURISDICTIONS, WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import '@xengage/gw-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import WMICPAPolicyLossHistoryDetailView from './WMICPAPolicyLossHistoryDetailView/WMICPAPolicyLossHistoryDetailView';

import messages from './WMICPAPolicyLossHistory.messages';
import metadata from './WMICPAPolicyLossHistory.metadata.json5';
import styles from './WMICPAPolicyLossHistory.module.scss';

const DRIVERS_PATH = 'lobs.personalAuto.driverDTOs';

function WMICPAPolicyLossHistory(props) {
    const {
        insuranceHistoryVM,
        policyVM,
        baseState
    } = props;

    const translator = useContext(TranslatorContext);
    const isBaseStateQC = baseState === JURISDICTIONS.QUEBEC;
    const drivers = _.get(policyVM, `${DRIVERS_PATH}.value`);

    const localDate2Date = (data) => WMICDateTimeService.toMidnightDate(data.lossDate.value);

    const getClaimNumber = (data) => data.value.claimNumber;

    const getPolicyNumber = (data) => data.value.policyNum;

    const isChargeable = useCallback((data) => _.get(data, 'paPriorLoss.value.useForRating')
            ? translator(messages.yes)
            : translator(messages.no), [translator]);
    
    const isUsedForRating = useCallback((data) => _.get(data, 'paPriorLoss.value.useForRating')
            ? translator(messages.yes)
            : translator(messages.no), [translator]);
    
    const isRatingReasonVisible = data => !!data?.paPriorLoss?.ratingReason?.value;

    const getDriver = useCallback((data) => {
        const driverName = data.value.paPriorLoss.vehicleOperator;

        const driver = _.find(drivers, currentDriver => currentDriver.person?.displayName === driverName);

        return driver ? `#${driver.driverNumber_WMIC}: ${driver.person?.displayName}` : `${translator(messages.reportedDriver)}`;
    }, [translator, drivers]);

    const isSuccess = useCallback((driver) => {
        const fcsaOrderedLossHistory = _.find(insuranceHistoryVM.fcsaOrderedLossHistory.value, {requestedDriver: driver.displayName});

        return fcsaOrderedLossHistory?.fcsaOrderStatus === 'Received';
    }, [insuranceHistoryVM.fcsaOrderedLossHistory.value]);

    const isError = useCallback((driver) => {
        const fcsaOrderedLossHistory = _.find(insuranceHistoryVM.fcsaOrderedLossHistory.value, {requestedDriver: driver.displayName});

        if (fcsaOrderedLossHistory?.fcsaOrderStatus === 'Error') {
            return true;
        }

        return false;
    }, [insuranceHistoryVM.fcsaOrderedLossHistory.value]);

    const getDriverName = useCallback((driver) => {
        const fcsaOrderedLossHistory = _.find(insuranceHistoryVM.fcsaOrderedLossHistory.value, {requestedDriver: driver.displayName});

        if (fcsaOrderedLossHistory?.fcsaOrderStatus) {
            if (fcsaOrderedLossHistory.fcsaOrderStatus === 'Error') {
                return translator(messages.FCSADriverNameError, {driverName: driver.displayName, date: WMICDateTimeService.toMidnightDate(fcsaOrderedLossHistory.dateFCSAOrdered)});
            }

            if (fcsaOrderedLossHistory.fcsaOrderStatus === 'Received') {
                return translator(messages.FCSADriverNameSuccess, {driverName: driver.displayName, date: WMICDateTimeService.toMidnightDate(fcsaOrderedLossHistory.dateFCSAOrdered)});
            }
        }

        return driver.displayName;
    }, [translator, insuranceHistoryVM.fcsaOrderedLossHistory.value]);

    const getFCSAdrivers = () => {
        const fcsaProvided = _.get(insuranceHistoryVM, 'fcsaProvided.value');

        return drivers?.map((driver) => (
            <div className={styles.fcsaDriverContainer}>
                {isSuccess(driver) && !isError(driver) && !fcsaProvided && (
                    <div className={cx(styles.badge, styles.badgeApproved)}>
                        <i className="mir mi-check" />
                        {translator(messages.FCSASuccess)}
                    </div>
                )}
                {fcsaProvided && (
                    <div className={cx(styles.badge, styles.badgeNotRequested)}>
                        <i className="mir mi-remove" />
                        {translator(messages.FCSANotRequested)}
                    </div>
                )}
                {!isSuccess(driver) && isError(driver) && !fcsaProvided && (
                    <div className={cx(styles.badge, styles.badgeError)}>
                        <i className="mir mi-close" />
                        {translator(messages.FCSAError)}
                    </div>
                )}
                <span className={isError(driver) && styles.fcsaError}>{getDriverName(driver)}</span>
            </div>
        ))
    };

    const overrideProps = {
        '@field': {
            parentNode: insuranceHistoryVM,
            readOnly: true
        },
        FCSALossHistoryQCSectionContainer: {
            visible: isBaseStateQC
        },
        fcsaDriversContainer: {
            content: getFCSAdrivers()
        },
        noPriorLossesContainer: {
            visible: _.get(insuranceHistoryVM, 'priorLosses.value', []).length === 0
        },
        insuranceLossHistoryDataList: {
            VMData: [
                {
                    headerText: translator(messages.driverName),
                    getData: getDriver,
                    classNameColumn: styles.headerBottom
                },
                {
                    headerText: isBaseStateQC ? translator(messages.claimNumber) : translator(messages.policyNumber),
                    getData: isBaseStateQC ? getClaimNumber : getPolicyNumber,
                    classNameColumn: styles.headerTop
                },
                {
                    headerText: translator(messages.atFault),
                    path: 'paPriorLoss.atFault'
                },
                {
                    headerText: translator(messages.lossDate),
                    getData: localDate2Date
                },
                {
                    headerText: translator(messages.vehicleChargeable),
                    getData: isChargeable
                },
                {
                    headerText: translator(messages.vehicleUseForRating),
                    getData: isUsedForRating
                },
                {
                    headerText: translator(messages.vehicleRatingReason),
                    path: 'paPriorLoss.ratingReason',
                    visibilityCondition: isRatingReasonVisible
                },
            ],
            clickable: true,
            readOnly: true,
            startOpen: false,
            detailViewComponent: WMICPAPolicyLossHistoryDetailView,
            detailViewComponentProps:{
                getDriver,
                policyVM
            }
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={insuranceHistoryVM}
            overrideProps={overrideProps}
        />
    );
}

WMICPAPolicyLossHistory.propTypes = {
    insuranceHistoryVM: PropTypes.shape({}),
    policyVM: PropTypes.shape({}),
    baseState: PropTypes.string
};

export default WMICPAPolicyLossHistory;
