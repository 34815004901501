import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { CONSTANTS, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';

import metadata from './WMICVehiclesUsageCommercial.metadata.json5';
import messages from './WMICVehiclesUsageCommercial.messages.js';

function WMICVehiclesUsageCommercial({ id, showErrors, rateAsOfDate, vehicleVM, vehicles,
    commercialVM, setCommercialVM, onValidate, isEditMode }) {

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [availableTowingVehicles, setAvailableTowingVehicles] = useState([]);
    const translator = useContext(TranslatorContext);

    const getDisplayNameForTowingVehicle = (vehicle) => (vehicle.vehicleNumber_WMIC ? (`#${vehicle.vehicleNumber_WMIC} : `) : '') +
        (vehicle.year ? (`${vehicle.year} `) : '') +
        (vehicle.make ? (`${vehicle.make} `) : '') +
        (vehicle.model ? (`${vehicle.model} `) : ' ') +
        (vehicle.vin ? (`, ${vehicle.vin}`) : '');

    const isCommercialTowingVehicle = (vehicle) => vehicle &&
        vehicle.businessSegment_WMIC &&
        vehicle.businessSegment_WMIC === CONSTANTS.BUSINESS_SEGMENT_CODES.COMMERCIAL &&
        vehicle.commercialVehicle_WMIC &&
        vehicle.vehicleType === CONSTANTS.VEHICLE_TYPES.AUTO

    const getQualifyingTowingVehicles = () => vehicles && vehicles.length > 0 ?
        _.filter(vehicles.value, (vehicle) => isCommercialTowingVehicle(vehicle)) : [];

    const populateAvailableTowingVehiclesIfNeeded = useCallback(() => {
        if (_.get(vehicleVM, 'vehicleType.value.code') === CONSTANTS.VEHICLE_TYPES.TRAILER) {
            const newAvailableTowingVehicles = _.map(getQualifyingTowingVehicles(),
                (vehicle) => ({
                    code: vehicle.vin,
                    name: getDisplayNameForTowingVehicle(vehicle)
                }));
            return newAvailableTowingVehicles;
        }
        return [];
    }, [availableTowingVehicles]);

    const getIsRPCEffectiveForVehTypeAndBusSegment = (rpcCode) => {
        return WMICRPCUtil.getIsRPCEffectiveForVehTypeAndBusSegment(
            _.get(vehicleVM, 'ratingJurisdiction.value.code'),
            rateAsOfDate,
            _.get(vehicleVM, 'vehicleType.value.code'),
            _.get(vehicleVM, 'businessSegment_WMIC.value.code'),
            rpcCode);
    };

    const isRPC1777Effective = () => getIsRPCEffectiveForVehTypeAndBusSegment('1777');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate, commercialVM]);

    useEffect(() => {
        const isApplicable = _.get(commercialVM, 'tripsPerMonth.aspects.ocular');

        if (!isApplicable) {
            _.set(commercialVM, 'tripsPerMonth', undefined);
        }

    }, [_.get(commercialVM, 'tripsPerMonth.aspects.ocular')])

    const isPageMounted = useRef();

    useEffect(() => {
        if (_.get(commercialVM, 'towingVehicle.aspects.ocular', false)) {
            const newAvailableTowingVehicles = populateAvailableTowingVehiclesIfNeeded();
            setAvailableTowingVehicles(newAvailableTowingVehicles);
        }

        // Check isPageMounted.current to ensure this does not fire on initial render.
        if (isPageMounted.current) {
            const towingVehicleVIN = _.get(commercialVM, 'towingVehicle.value');
            if (towingVehicleVIN && isRPC1777Effective()) {
                const towingVehicle = vehicles.value.find(vehicle => vehicle.vin === towingVehicleVIN);
                _.set(commercialVM, 'grossVehicleWeight', _.get(towingVehicle, 'commercialVehicle_WMIC.grossVehicleWeight'));
            }
        } else {
            isPageMounted.current = true;
        }
    }, [_.get(commercialVM, 'towingVehicle.value')]);

    const resetVehicleHighHazardOperation = useCallback(() => {
        _.set(commercialVM, 'haulDangerousGoods', null);
        _.set(commercialVM, 'haulLivestock', null);
        _.set(commercialVM, 'haulLogs', null);
        _.set(commercialVM, 'haulLumber', null);
        _.set(commercialVM, 'performMovingStorage', null);
        _.set(commercialVM, 'operateInOilFields', null);
        _.set(commercialVM, 'haulOversizedLoads', null);
        _.set(commercialVM, 'haulSandStoneGravelEarth', null);
        _.set(commercialVM, 'operateOnWinterIceRoads', null);
        _.set(commercialVM, 'haulBulkLiquids', null);
    }, [commercialVM]);

    const resetAdditionalExposureQuestions = () => {
        _.set(commercialVM, 'highHazardOperationType', null);
        _.set(commercialVM, 'dangerousGoodsType', null);
        resetVehicleHighHazardOperation();
    }

    const isRPC1937Effective = () => getIsRPCEffectiveForVehTypeAndBusSegment('1937');

    const handleTypeOfUseChange = (typeOfUseValue) => {
        _.set(commercialVM, 'typeOfUse', typeOfUseValue);
        _.set(commercialVM, 'otherTypeOfUseDesc', undefined);
        _.set(commercialVM, 'subTypeOfUse', undefined);
        _.set(commercialVM, 'classCodes', undefined);

        if (isRPC1937Effective()) {
            resetAdditionalExposureQuestions();
        }

        setCommercialVM(commercialVM);
    }

    const handleHighHazardOperationsTypeChange = useCallback((highHazardOperationsTypeValue) => {
        _.set(commercialVM, 'highHazardOperationType', highHazardOperationsTypeValue);

        resetVehicleHighHazardOperation();

        switch(highHazardOperationsTypeValue) {
            case CONSTANTS.HIGH_HAZARD_OPERATION.DANGEROUS_GOODS:
                _.set(commercialVM, 'haulDangerousGoods', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.LIVESTOCK:
                _.set(commercialVM, 'haulLivestock', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.LOGS:
                _.set(commercialVM, 'haulLogs', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.LUMBER:
                _.set(commercialVM, 'haulLumber', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.MOVING_AND_STORAGE:
                _.set(commercialVM, 'performMovingStorage', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.OIL_FIELD:
                _.set(commercialVM, 'operateInOilFields', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.OVERSIZED_LOAD:
                _.set(commercialVM, 'haulOversizedLoads', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.SAND_STONE_GRAVEL_AND_EARTH:
                _.set(commercialVM, 'haulSandStoneGravelEarth', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.WINTER_ICE_ROAD:
                _.set(commercialVM, 'operateOnWinterIceRoads', true);
                break;
            case CONSTANTS.HIGH_HAZARD_OPERATION.NON_HAZARDOUS_BULK_LIQUIDS:
                _.set(commercialVM, 'haulBulkLiquids', true);
                break;
            default:
                break;
        }

        setCommercialVM(commercialVM);
    }, [commercialVM, setCommercialVM, resetVehicleHighHazardOperation]);

    const getDangerousGoodsTypes = (availableValues) => {
        let filteredDangerousGoodsTypes = isRPC1777Effective()
            ? availableValues
            : availableValues.filter((dangerousGoodsType) => dangerousGoodsType.code !== CONSTANTS.DANGEROUS_GOOD_TYPES.COMPRESSEDGAS
                && dangerousGoodsType.code !== CONSTANTS.DANGEROUS_GOOD_TYPES.EXPLOSIVES);

        filteredDangerousGoodsTypes = filteredDangerousGoodsTypes.map((availableValue) => {
            return {
                code: availableValue.code,
                name: translator({
                    id: availableValue.name,
                    defaultMessage: availableValue.name
                })
            };
        });

        return filteredDangerousGoodsTypes;
    };

    const overrideProps = {
        '@field': {
            parentNode: commercialVM,
            readOnly: !isEditMode
        },
        highHazardOperationsContainer: {
            visible: _.get(commercialVM, 'highHazardContainer.aspects.ocular', false)
        },
        additionalExposureContainer: {
            visible: _.get(commercialVM, 'additionalExposureContainer.aspects.ocular', false)
        },
        notVisibleForQCTaxiContainer: {
            visible: _.get(commercialVM, 'notVisibleForQCTaxiContainer.aspects.ocular', false)
        },
        notVisibleForQCTaxiContainerExtra: {
            visible: _.get(commercialVM, 'notVisibleForQCTaxiContainer.aspects.ocular', false)
        },
        bodyType: {
            readOnly: !_.get(commercialVM, 'bodyType.aspects.editable', false)
        },
        typeOfUse: {
            onValueChange: handleTypeOfUseChange,
            readOnly: !_.get(commercialVM, 'typeOfUse.aspects.editable', false) || !isEditMode
        },
        otherTypeOfUseDesc: {
            readOnly: !_.get(commercialVM, 'otherTypeOfUseDesc.aspects.editable', false) || !isEditMode
        },
        subTypeOfUse: {
            required: _.get(commercialVM, 'subTypeOfUse.aspects.availableValues').length > 0 && !_.isEmpty(_.get(commercialVM, 'typeOfUse.value')),
            visible: _.get(commercialVM, 'subTypeOfUse.aspects.availableValues').length > 0 && !_.isEmpty(_.get(commercialVM, 'typeOfUse.value')),
            readOnly: !_.get(commercialVM, 'subTypeOfUse.aspects.editable', false) || !isEditMode
        },
        commercialVehicleClass: {
            availableValues: _.map(_.get(commercialVM, 'classCodes.value'), ((code) => ({ code, name: code }))),
            readOnly: !_.get(commercialVM, 'classCode.aspects.editable', false) || !isEditMode
        },
        towingVehicle: {
            availableValues: availableTowingVehicles,
            alwaysShowPlaceholder: true,
            visible: _.get(commercialVM, 'towingVehicle.aspects.ocular', false),
        },
        grossVehicleWeight: {
            readOnly: !_.get(commercialVM, 'grossVehicleWeight.aspects.editable', false) || !isEditMode
        },
        radiusOfOperation: {
            readOnly: !_.get(commercialVM, 'radiusOfOperation.aspects.editable', false) || !isEditMode
        },
        usedForDelivery: {
            readOnly: !_.get(commercialVM, 'usedForDelivery.aspects.editable', false) || !isEditMode
        },
        deliveryType: {
            readOnly: !_.get(commercialVM, 'deliveryType.aspects.editable', false) || !isEditMode
        },
        highHazardHeading: {
            title: isRPC1777Effective() ? translator(messages.highHazardAlternateHeading) : translator(messages.highHazardHeading)
        },
        bulkLiquids: {
            label: isRPC1777Effective() ? translator(messages.bulkLiquidsAlternate) : translator(messages.bulkLiquids)
        },
        dangerousGoodsType: {
            availableValues: getDangerousGoodsTypes(_.get(commercialVM, 'dangerousGoodsType.aspects.availableValues'))
        },
        highHazardOperationsType: {
            onValueChange: handleHighHazardOperationsTypeChange
        }
    };

    return (
        <ViewModelForm
            model={commercialVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            onModelChange={setCommercialVM}
            showErrors={showErrors}
            onValidationChange={setComponentValidation}
        />
    );
}

export default WMICVehiclesUsageCommercial;
